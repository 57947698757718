import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Privacy from '../views/Privacy.vue'
import Erstinformation from '../views/Erstinformation.vue'
import Imprint from '../views/Imprint.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'index',
        component: Index
    },
    {
        path: "/datenschutz",
        component: Privacy
    },
    {
        path: "/impressum",
        component: Imprint
    },
    {
        path: "/erstinformation",
        component: Erstinformation
    },
    {
        path: '/leistungen/*',
        name: 'leistungen',

        component: function() {
            return import ('../views/Leistung.vue')
        }
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router