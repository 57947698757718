<template>
  <nav>
    <b-container>
      <router-link to="/" class="logolink"
        ><div class="logo"></div
      ></router-link>
      <div class="menu" :class="{open: menuopen}">
        <div
          v-for="menuitem in menuitems"
          :key="menuitem.name"
          class="menuitem"
          :class="{ active: menuitem.active }"
          @click="() => menuItemClicked(menuitem)"
        >
          <span>{{ menuitem.name }}</span>
        </div>
        <div class="menulink">
          <a href="https://meine-finanzen.digital/login" target="_blank">{{ $t('navigation.login') }}</a>
        </div>
      </div>
      <div class="mobile" @click="menuopen = !menuopen">
        <i v-if="menuopen"><font-awesome-icon icon="fa-solid fa-close" /></i>
        <i v-else><font-awesome-icon icon="fa-solid fa-bars" /></i>
      </div>
    </b-container>
  </nav>
</template>
<script>
export default {
  name: "Navbar",
  data() {
    return {
      menuitems: [
        { name: this.$t('navigation.dienstleistungen'), dst: "dienstleistungen", active: true },
        { name: this.$t('navigation.versicherungscheck'), dst: "checkup", active: false },
        { name: this.$t('navigation.about'), dst: "about", active: false },
      ],
      menuopen: false
    };
  },
  methods: {
    menuItemClicked(menuitem) {
      for (let _menuitem of this.menuitems) {
        _menuitem.active = false;
      }
      menuitem.active = true;
      try {
        this.$router.push("/")
      }
      catch(e){
        console.log("bla")
      }
      this.scrollTo(menuitem.dst);
      this.menuopen = false;
    },

    scrollTo(id) {
      const yOffset = -150;
      const element = document.getElementById(id);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles.scss";

nav {
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 101;
  font-weight: 600;
  box-shadow: -3px 6px 25px 1px rgba(0, 0, 0, 0.15);
  .container {
    display: flex;
    flex-direction: row;
    a.logolink {
      display: inline-block;
      height: 70px;
      width: 230px;
      margin: 20px 0;
      position: relative;

      .logo {
        background-image: url("@/assets/logo_full.png");
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
      }
    }
    .mobile {
      position: absolute;
      right: 40px;
      top: 20px;
      font-size: 30px;
      display: none;
    }
    .menu {
      display: flex;
      flex-direction: row;
      margin: auto 0 auto auto;
      max-width: 100%;
      .menulink {
        padding-left: 20px;
        border-left: 1px solid #ccc;
        color: $accent_dark;
      }
      .menuitem {
        position: relative;
        padding-bottom: 5px;
        margin: 0 20px;
        &::after {
          content: "";
          transition: all 0.25s ease;
          display: inline-block;
          width: 0%;
          height: 2px;
          background-color: $accent;
          position: absolute;
          bottom: 0;
          left: 0;
        }
        
        &:hover {
          &::after {
            width: 100%;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          cursor: pointer;
        }
        .submenu {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  nav .container {
    .mobile {
      display: inline-block;
    }
    a.logolink {
      //margin-left: auto;
      margin-right: auto;
    }
    .menu {
      flex-direction: column;
      position: absolute;
      top: 90px;
      background-color: #111;
      width: 100%;
      left: 100%;
      color: white;
      //opacity: 0;
      transition: left .25s ease;
      &.open {
        left: 0%;
        opacity: 1;
      }
      .menuitem, .menulink {
        border: none;
        padding: 15px 0;
        max-width: 300px;
        margin: 0 auto;
      }
    }
  }
}
</style>