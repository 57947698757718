<template>
  <div id="home">
    <div class="carousel">
      <div class="overlay">
        <b-container>
          <div class="headline wow slideInLeft">
            <!--<span>Ben Beutler-Huth</span>-->
            <h1 v-html="$t('index.headline.headline')"></h1>
            <!--<p>
              {{ $t("index.headline.intro") }}
              <br />
              <a @click="scroll"
                >{{ $t("index.headline.cta") }}
                <font-awesome-icon icon="fa-solid fa-arrow-right"
              /></a>
            </p>-->
          </div>
        </b-container>
      </div>
    </div>
    <section id="dienstleistungen">
      <b-container>
        <h2>{{ $t("index.dienstleistungen.headline") }}</h2>
        <p>
          {{ $t("index.dienstleistungen.intro") }}
        </p>
        <b-row>
          <b-col
            lg="4"
            v-for="service in $t('index.dienstleistungen.leistungen')"
            :key="service.name"
            md="6"
            sm="12"
          >
            <router-link :to="service._link">
              <div class="box">
                <img
                  :src="service._image"
                  :style="{
                    'object-position': service._position
                      ? service._position
                      : 'center',
                  }"
                />
                <b>{{ service.name }}</b>
                <p>{{ service.text }}</p>
                <i><font-awesome-icon icon="fa-solid fa-arrow-right" /></i>
              </div>
            </router-link>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section id="checkup">
      <div class="overlay">
        <h3>{{ $t("index.checkup.headline") }}</h3>
        <p>
          {{ $t("index.checkup.intro") }}
        </p>
        <b-button disabled>{{ $t("index.checkup.do") }}</b-button>
      </div>
    </section>

    <!--<section>
      <b-container>
        <h2>Versicherungen im Überblick</h2>
        <p>
          Reprehenderit mollit consectetur ullamco mollit consectetur nisi
          nostrud sunt reprehenderit cupidatat proident ex voluptate. Velit
          minim dolore do exercitation
        </p>
        <div class="customer-select">
          <div
            class="customer type"
            v-for="type in customers.types"
            :key="type"
            :class="{ active: type == customers.selected_type }"
          >
            <span>{{ type }}</span>
          </div>
          <div class="seperator"></div>
          <div
            class="customer attribute"
            v-for="attribute in customers.attributes"
            :key="attribute"
            :class="{ active: attribute == customers.selected_attribute }"
          >
            <span>{{ attribute }}</span>
          </div>
        </div>
        <b-row>
          <b-col lg="12">
            <div class="chips"></div>
          </b-col>
          <b-col lg="6">
            <div class="chart">
              <apexchart
                type="polarArea"
                height="500"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="info">
              <b-row>
                <b-col
                  lg="6"
                  v-for="(service, i) in [ services[2], services[1], services[4], services[7] ]"
                  :key="service.name"
                >
                  <div
                    class="box small"
                    :style="{ 'border-left-color': service.border }"
                  >
                    <img :src="service.icon" />
                    <h4>{{ i + 1 }}. {{ service.name }}</h4>
                    <ul>
                      <li>Anim sint voluptate esse</li>
                      <li>Quis mollit sit ea eu anim</li>
                      <li>Eiusmod consectetur pariatur</li>
                    </ul>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>-->

    <section id="about">
      <b-container>
        <h2>{{ $t("index.about.headline") }}</h2>
        <div class="presentation">
          <b-row>
            <b-col lg="3">
              <div class="portrait"></div>
            </b-col>
            <b-col lg="9">
              <h3>
                Ben Beutler-Huth <small>{{ $t("index.about.title") }}</small>
              </h3>
              <p v-html="$t('index.about.about_text')"></p>
              <ul>
                <li
                  v-for="(competence, i) in $t('index.about.competences')"
                  :key="i"
                >
                  {{ competence }}
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </section>

    <!--<section id="nachhaltigkeit">
      <b-container>
        <h2>Nachhaltigkeit</h2>
        <p>
          Reprehenderit mollit consectetur ullamco mollit consectetur nisi
          nostrud sunt reprehenderit cupidatat proident ex voluptate. Velit
          minim dolore do exercitation
        </p>
        <div class="stream">
          <div
            class="stream-item"
            v-for="(item, key) in environmental"
            :key="key"
          >
            <b>{{ item.name }}</b>
            <i><font-awesome-icon :icon="item.icon"></font-awesome-icon></i>
            <p>{{ item.text }}</p>
          </div>
        </div>
      </b-container>
    </section>-->
  </div>
</template>
<script>
export default {
  name: "Home",
  methods: {
    scroll() {
      const yOffset = -150;
      const element = document.getElementById("dienstleistungen");
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    },
  },
  data() {
    return {
      /*customers: {
        types: ["Auszubildender", "Student", "Beamter"],
        attributes: ["Eigentümer", "Mieter", "Vermieter"],
        selected_type: "Student",
        selected_attribute: "Mieter",
      },*/
      services: [],
      /*chartOptions: {
        colors: ["#8d4417"],
        chart: {
          width: 380,
          type: "polarArea",
        },
        labels: [],
        fill: {
          opacity: 0.8,
        },
        stroke: {
          width: 2,
          colors: ["#f0f0f0"],
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.seriesIndex];
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return [
                "unwichtig",
                "etwas wichtig",
                "mittelwichtig",
                "wichtig",
                "sehr wichtig",
              ][val - 1];
            },
          },
        },
        legend: {
          show: false,
          //position: "right",
        },
      },

      series: [5, 3, 5, 2, 4, 2, 3, 1, 4],*/
    };
  },
  beforeMount() {
    //this.chartOptions.labels = this.services.map((service) => service.name);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles.scss";

.carousel {
  display: inline-block;
  width: 100%;
  height: 75vh;
  background-image: url("@/assets/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  .container {
    display: inline-flex;
    height: 100%;
  }
  .headline {
    display: inline-flex;
    flex-direction: column;
    max-width: 100%;
    position: relative;
    margin-bottom: auto;
    margin-top: 20vh;
    padding: 20px 70px;

    &:before {
      content: "";
      position: absolute;
      display: inline-block;
      background-color: $accent;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 4px;
    }

    span {
      font-size: 32px;
      color: $accent;
    }
    h1 {
      color: white;
      font-size: 3.5rem;
      letter-spacing: 5px;
      margin: 10px 0;
      white-space: nowrap;
      text-align: left !important;
    }
    p {
      color: #999;
      max-width: 700px;
      margin: 0;
      a {
        display: inline-block;
        margin-top: 15px;
        color: $accent_dark !important;
        transition: color 0.25s ease;
        &:hover {
          color: $accent !important;
          cursor: pointer;
        }
        svg,
        i {
          display: inline-block;
          margin-left: 10px;
        }
      }
    }
  }
}
.box {
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  background-color: #000;
  transition: all 0.25s ease;
  position: relative;
  min-height: 200px;
  overflow: hidden;
  border-left: 4px solid;
  border-left-color: $accent !important;

  p,
  b,
  i {
    color: white !important;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0.4;
    top: 0;
    left: 0;
    background-position: center;
  }
  b {
    font-size: 1.2rem;
  }
  p {
    color: #333;
    max-width: 100%;
    z-index: 100;
  }

  &:hover {
    box-shadow: -3px 6px 25px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}

.customer-select {
  display: flex;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 50px;

  .customer {
    background-color: white;
    font-size: 24px;
    flex-grow: 2;
    padding: 10px 35px;
    span {
      display: block;
      text-align: center;
    }
    &:first-child {
      border-top-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
    }
    &.active {
      border-bottom: 2px solid $accent;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .seperator {
    display: inline-block;
    width: 1px;
  }
}

$icon-size: 50px;
.stream {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 75px;
  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: #ccc;
    top: 63px;
  }
  .stream-item {
    display: flex;
    flex-direction: column;
    b,
    p {
      text-align: center;
      max-width: 250px;
    }
    b {
      color: $accent;
    }
    i {
      display: inline-flex;
      z-index: 100;
      width: $icon-size;
      height: $icon-size;
      font-size: 1.25rem;
      border-radius: 50%;
      color: white;
      background-color: $accent;
      box-shadow: -3px 6px 25px 1px rgba(0, 0, 0, 0.2);
      margin: 15px auto;
      svg {
        margin: auto;
      }
    }
  }
}

#checkup {
  background-image: url("@/assets/table.jpeg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  .overlay {
    padding: 75px 0;
    flex-direction: column;
    h3 {
      text-align: center;
      margin: 0;
      color: $accent;
    }
    p {
      max-width: 600px;
      text-align: center;
      color: #aaa;
      margin: 25px auto;
    }
    button {
      margin: 0 auto;
    }
  }
}

#about {
  .presentation {
    background-color: white;
    padding: 30px;
    [class^="col"] {
      &:first-child {
        border-right: 1px solid #ccc;
        padding-right: 50px;
      }
      &:last-child {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
    .portrait {
      background-image: url("@/assets/portrait.jpg");
      background-size: contain;
      background-position: top;
      height: 300px;
      background-repeat: no-repeat;
      filter: blur(0px);
    }
    h3 {
      font-weight: bold;
      font-size: 2rem;
      border-bottom: 1px solid #ccc;
      margin-bottom: 10px;
      padding-bottom: 10px;
      small {
        font-size: 1.5rem;
        font-weight: normal;
        color: $accent;
      }
    }
  }
}

@media (max-width: 1400px) {
  .box p {
    min-height: 100px;
  }
}

@media (max-width: 1200px) {
  .carousel,
  #checkup {
    background-attachment: unset;
  }
}

@media (max-width: 992px) {
  .headline {
    text-align: center;
  }
  #about .presentation {
    [class^="col-"] {
      border: none !important;
      padding: 0 10px !important;
      .portrait {
        margin: 0 auto;
      }
      p {
        text-align: justify;
      }
      &:first-child {
        padding-bottom: 20px !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .carousel .headline {
    padding-left: 40px;
    h1 {
      font-size: 2.2rem;
    }
  }
}
</style>
