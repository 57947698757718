<template>
  <div class="container">
    <h1>Impressum:</h1>
    <br />
    Ben Beutler-Huth - Makler für Versicherungen<br />
    <br />
    Telefon: +49 173 42 79 202<br />
    E-Mail: kontakt@beutler-makler.de<br />
    Internet: www.beutler-makler.de<br />
    <br />
    Vermittlerregister-Nr.: D-LSOI-59QCZ-99 <br />
    Erlaubnis als unabhängiger Versicherungsmakler nach § 34 Abs. 1 GewO
    erteilt.<br />
    Adresse: Wilhelmstraße 135, 14624 Dallgow-Döberitz<br />
    <br />
    Das Vermittlerregister wird geführt bei: <br />
    Deutscher Industrie- und Handelskammertag (DIHK) e.V.; Breite Straße 29,
    10178 Berlin www.vermittlerregister.info <br />
    Telefon: 0180-6005850 (0,20 Euro/Anruf), Fax: +49 (0) 30-20308-1000, E-Mail:
    infocenter@berlin.dihk.de <br />
    <br />
    Zuständige Aufsichtsbehörde: <br />
    Industrie- und Handelskammer Potsdam, Breite Straße 2 a-c, 14467 Potsdam,
    Tel.: 0331-27860<br />
    <br />
    Beteiligungen: <br />
    Der Gewerbetreibende hält keine unmittelbaren oder mittelbaren Beteiligungen
    von mehr als 10 % der Stimmrechte oder des Kapitals an einem
    Versicherungsunternehmen. Ein Versicherungsunternehmen hält keine
    mittelbaren oder unmittelbaren Beteiligungen von mehr als 10 % der
    Stimmrechte oder des Kapitals des Gewerbetreibenden.<br />
    <br />
    Schlichtungsstellen: <br />
    - Versicherungsombudsmann e.V., Postfach 08 06 32, 10006 Berlin,
    www.versicherungsombudsmann.de <br />
    - Ombudsmann für die private Kranken- und Pflegeversicherung, Kronenstraße
    13, 10117 Berlin, www.pkv-ombudsmann.de<br />
    <br />
    Berufsrechtliche Regelungen:<br />
    - § 34d Gewerbeordnung (GewO)<br />
    - §§ 59 - 68 Gesetz über den Versicherungsvertrag (VVG)<br />
    - § 48b Versicherungsaufsichtsgesetz (VAG)<br />
    - Verordnung über die Versicherungsvermittlung und -beratung (VersVermV)<br />
  </div>
</template>
<script>
export default {
  mounted() {
    setTimeout(() => window.scrollTo(0, 0), 250);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles.scss";
h1 {
  margin-top: 20px;
}
.container {
  padding-bottom: 20px;
}
</style>
