<template>
  <div class="container">
    <h1>Datenschutzerklärung</h1>
    <p>Stand: 21. Januar 2023</p>
    <h2>Inhaltsübersicht</h2>
    <ul class="index">
      <li><a class="index-link" href="#m3">Verantwortlicher</a></li>
      <li>
        <a class="index-link" href="#mOverview">Übersicht der Verarbeitungen</a>
      </li>
      <li>
        <a class="index-link" href="#m13">Maßgebliche Rechtsgrundlagen</a>
      </li>
      <li><a class="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
      <li>
        <a class="index-link" href="#m25"
          >Übermittlung von personenbezogenen Daten</a
        >
      </li>
      <li>
        <a class="index-link" href="#m24">Datenverarbeitung in Drittländern</a>
      </li>
      <li><a class="index-link" href="#m12">Löschung von Daten</a></li>
      <li><a class="index-link" href="#m134">Einsatz von Cookies</a></li>
      <li>
        <a class="index-link" href="#m225"
          >Bereitstellung des Onlineangebotes und Webhosting</a
        >
      </li>
      <li>
        <a class="index-link" href="#m182">Kontakt- und Anfragenverwaltung</a>
      </li>
      <li>
        <a class="index-link" href="#m17"
          >Newsletter und elektronische Benachrichtigungen</a
        >
      </li>
      <li>
        <a class="index-link" href="#m638"
          >Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</a
        >
      </li>
      <li>
        <a class="index-link" href="#m263"
          >Webanalyse, Monitoring und Optimierung</a
        >
      </li>
      <li>
        <a class="index-link" href="#m136"
          >Präsenzen in sozialen Netzwerken (Social Media)</a
        >
      </li>
      <li>
        <a class="index-link" href="#m328"
          >Plugins und eingebettete Funktionen sowie Inhalte</a
        >
      </li>
      <li>
        <a class="index-link" href="#m15"
          >Änderung und Aktualisierung der Datenschutzerklärung</a
        >
      </li>
      <li>
        <a class="index-link" href="#m10">Rechte der betroffenen Personen</a>
      </li>
      <li><a class="index-link" href="#m42">Begriffsdefinitionen</a></li>
    </ul>
    <h2 id="m3">Verantwortlicher</h2>
    <p>
      Ben Beutler-Huth<br />Wilhelmstraße 135<br />14624 Dallgow-Döberitz /
      Deutschland
    </p>
    E-Mail-Adresse:
    <p>kontakt@beutler-makler.de</p>
    <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
    <p>
      Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die
      Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
      Personen.
    </p>
    <h3>Arten der verarbeiteten Daten</h3>
    <ul>
      <li>Bestandsdaten.</li>
      <li>Kontaktdaten.</li>
      <li>Inhaltsdaten.</li>
      <li>Nutzungsdaten.</li>
      <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
      <li>Event-Daten (Facebook).</li>
    </ul>
    <h3>Kategorien betroffener Personen</h3>
    <ul>
      <li>Kommunikationspartner.</li>
      <li>Nutzer.</li>
    </ul>
    <h3>Zwecke der Verarbeitung</h3>
    <ul>
      <li>Kontaktanfragen und Kommunikation.</li>
      <li>Sicherheitsmaßnahmen.</li>
      <li>Direktmarketing.</li>
      <li>Reichweitenmessung.</li>
      <li>Verwaltung und Beantwortung von Anfragen.</li>
      <li>Feedback.</li>
      <li>Marketing.</li>
      <li>Profile mit nutzerbezogenen Informationen.</li>
      <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
      <li>Informationstechnische Infrastruktur.</li>
    </ul>
    <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
    <p>
      Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO,
      auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie
      zur Kenntnis, dass neben den Regelungen der DSGVO nationale
      Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
      können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
      maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
    </p>
    <ul>
      <li>
        <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die
        betroffene Person hat ihre Einwilligung in die Verarbeitung der sie
        betreffenden personenbezogenen Daten für einen spezifischen Zweck oder
        mehrere bestimmte Zwecke gegeben.
      </li>
      <li>
        <strong
          >Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong
        >
        - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
        Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
        Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person,
        die den Schutz personenbezogener Daten erfordern, überwiegen.
      </li>
    </ul>
    <p>
      Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale
      Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das
      Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
      Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
      insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
      Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
      personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur
      Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall
      einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für
      Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im
      Hinblick auf die Begründung, Durchführung oder Beendigung von
      Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten.
      Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur
      Anwendung gelangen.
    </p>
    <h2 id="m27">Sicherheitsmaßnahmen</h2>
    <p>
      Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung
      des Stands der Technik, der Implementierungskosten und der Art, des
      Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
      unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der
      Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete
      technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes
      Schutzniveau zu gewährleisten.
    </p>
    <p>
      Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
      Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und
      elektronischen Zugangs zu den Daten als auch des sie betreffenden
      Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und
      ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
      Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen
      auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den
      Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl
      von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
      Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
      Voreinstellungen.
    </p>
    <p>
      TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot
      übermittelten Daten zu schützen, nutzen wir eine TLS-Verschlüsselung. Sie
      erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der
      Adresszeile Ihres Browsers.
    </p>
    <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
    <p>
      Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor,
      dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige
      Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber
      offengelegt werden. Zu den Empfängern dieser Daten können z.B. mit
      IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und
      Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen
      Fällen beachten wir die gesetzlichen Vorgaben und schließen insbesondere
      entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten
      dienen, mit den Empfängern Ihrer Daten ab.
    </p>
    <h2 id="m24">Datenverarbeitung in Drittländern</h2>
    <p>
      Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen
      Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die
      Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der
      Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder
      Unternehmen stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen
      Vorgaben.
    </p>
    <p>
      Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich
      erforderlicher Übermittlung verarbeiten oder lassen wir die Daten nur in
      Drittländern mit einem anerkannten Datenschutzniveau, vertraglichen
      Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission,
      beim Vorliegen von Zertifizierungen oder verbindlicher internen
      Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO,
      Informationsseite der EU-Kommission:
      <a
        href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
        target="_blank"
        >https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de</a
      >).
    </p>
    <p></p>
    <h2 id="m12">Löschung von Daten</h2>
    <p>
      Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
      Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen
      widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck
      der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht
      erforderlich sind). Sofern die Daten nicht gelöscht werden, weil sie für
      andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren
      Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt
      und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
      handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder
      deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
      Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
      juristischen Person erforderlich ist.
    </p>
    <p>
      Im Rahmen unserer Datenschutzhinweise können wir den Nutzern weitere
      Informationen zu der Löschung sowie zu der Aufbewahrung von Daten
      mitteilen, die speziell für die jeweiligen Verarbeitungsprozesses gelten.
    </p>
    <h2 id="m134">Einsatz von Cookies</h2>
    <p>
      Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die
      Informationen auf Endgeräten speichern und Informationen aus den
      Endgeräten auslesen. Z.B. um den Login-Status in einem Nutzerkonto, einen
      Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder verwendete
      Funktionen eines Onlineangebotes speichern. Cookies können ferner zu
      unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der
      Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten sowie der
      Erstellung von Analysen der Besucherströme.
    </p>
    <p>
      <strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang
      mit den gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern
      eine vorhergehende Einwilligung ein, außer wenn diese gesetzlich nicht
      gefordert ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn
      das Speichern und das Auslesen der Informationen, also auch von Cookies,
      unbedingt erforderlich sind, um dem den Nutzern einen von ihnen
      ausdrücklich gewünschten Telemediendienst (also unser Onlineangebot) zur
      Verfügung zu stellen. Die widerrufliche Einwilligung wird gegenüber den
      Nutzern deutlich kommuniziert und enthält die Informationen zu der
      jeweiligen Cookie-Nutzung.
    </p>
    <p>
      <strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf
      welcher datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen
      Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir
      Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist die
      Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung.
      Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf
      Grundlage unserer berechtigten Interessen (z.B. an einem
      betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung
      seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung
      unserer vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies
      erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen. Zu
      welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären wir
      im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren
      Einwilligungs- und Verarbeitungsprozessen auf.
    </p>
    <p>
      <strong>Speicherdauer: </strong>Im Hinblick auf die Speicherdauer werden
      die folgenden Arten von Cookies unterschieden:
    </p>
    <ul>
      <li>
        <strong
          >Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong
        >
        Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
        Online-Angebot verlassen und sein Endgerät (z.B. Browser oder mobile
        Applikation) geschlossen hat.
      </li>
      <li>
        <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch
        nach dem Schließen des Endgerätes gespeichert. So können beispielsweise
        der Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt
        werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die
        mit Hilfe von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung
        verwendet werden. Sofern wir Nutzern keine expliziten Angaben zur Art
        und Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung
        der Einwilligung), sollten Nutzer davon ausgehen, dass Cookies permanent
        sind und die Speicherdauer bis zu zwei Jahre betragen kann.
      </li>
    </ul>
    <p>
      <strong
        >Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong
      >Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit
      widerrufen und zudem einen Widerspruch gegen die Verarbeitung entsprechend
      den gesetzlichen Vorgaben im Art. 21 DSGVO einlegen. Nutzer können ihren
      Widerspruch auch über die Einstellungen ihres Browsers erklären, z.B.
      durch Deaktivierung der Verwendung von Cookies (wobei dadurch auch die
      Funktionalität unserer Online-Dienste eingeschränkt sein kann). Ein
      Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken
      kann auch über die Websites
      <a href="https://optout.aboutads.info" target="_blank"
        >https://optout.aboutads.info</a
      >
      und
      <a href="https://www.youronlinechoices.com/" target="_blank"
        >https://www.youronlinechoices.com/</a
      >
      erklärt werden.
    </p>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong
          >Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: </strong
        >Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in
        dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies,
        bzw. der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens
        genannten Verarbeitungen und Anbieter eingeholt sowie von den Nutzern
        verwaltet und widerrufen werden können. Hierbei wird die
        Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut
        wiederholen zu müssen und die Einwilligung entsprechend der gesetzlichen
        Verpflichtung nachweisen zu können. Die Speicherung kann serverseitig
        und/oder in einem Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe
        vergleichbarer Technologien) erfolgen, um die Einwilligung einem Nutzer,
        bzw. dessen Gerät zuordnen zu können. Vorbehaltlich individueller
        Angaben zu den Anbietern von Cookie-Management-Diensten, gelten die
        folgenden Hinweise: Die Dauer der Speicherung der Einwilligung kann bis
        zu zwei Jahren betragen. Hierbei wird ein pseudonymer
        Nutzer-Identifikator gebildet und mit dem Zeitpunkt der Einwilligung,
        Angaben zur Reichweite der Einwilligung (z. B. welche Kategorien von
        Cookies und/oder Diensteanbieter) sowie dem Browser, System und
        verwendeten Endgerät gespeichert.
      </li>
    </ul>
    <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
    <p>
      Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur
      Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
      IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen
      unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu
      übermitteln.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
        Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-,
        Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
        Identifikationsnummern, Einwilligungsstatus); Inhaltsdaten (z.B.
        Eingaben in Onlineformularen).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
        Nutzer von Onlinediensten).
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
        Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
        Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und
        technischen Geräten (Computer, Server etc.).); Sicherheitsmaßnahmen.
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f) DSGVO).
      </li>
    </ul>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff
        auf unser Onlineangebot wird in Form von so genannten "Server-Logfiles"
        protokolliert. Zu den Serverlogfiles können die Adresse und Name der
        abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs,
        übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp
        nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
        besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider
        gehören. Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
        eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
        (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
        DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre
        Stabilität sicherzustellen;
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f) DSGVO);
        <strong>Löschung von Daten:</strong> Logfile-Informationen werden für
        die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder
        anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken
        erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen
        Vorfalls von der Löschung ausgenommen.
      </li>
      <li>
        <strong>E-Mail-Versand und -Hosting: </strong>Die von uns in Anspruch
        genommenen Webhosting-Leistungen umfassen ebenfalls den Versand, den
        Empfang sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die
        Adressen der Empfänger sowie Absender als auch weitere Informationen
        betreffend den E-Mailversand (z.B. die beteiligten Provider) sowie die
        Inhalte der jeweiligen E-Mails verarbeitet. Die vorgenannten Daten
        können ferner zu Zwecken der Erkennung von SPAM verarbeitet werden. Wir
        bitten darum, zu beachten, dass E-Mails im Internet grundsätzlich nicht
        verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar auf dem
        Transportweg verschlüsselt, aber (sofern kein sogenanntes
        Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den
        Servern, von denen sie abgesendet und empfangen werden. Wir können daher
        für den Übertragungsweg der E-Mails zwischen dem Absender und dem
        Empfang auf unserem Server keine Verantwortung übernehmen;
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f) DSGVO).
      </li>
    </ul>
    <h2 id="m182">Kontakt- und Anfragenverwaltung</h2>
    <p>
      Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon
      oder via soziale Medien) sowie im Rahmen bestehender Nutzer- und
      Geschäftsbeziehungen werden die Angaben der anfragenden Personen
      verarbeitet soweit dies zur Beantwortung der Kontaktanfragen und etwaiger
      angefragter Maßnahmen erforderlich ist.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
        Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
        Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
        Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. B.
        IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
      </li>
      <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
        Kommunikation; Verwaltung und Beantwortung von Anfragen; Feedback (z.B.
        Sammeln von Feedback via Online-Formular); Bereitstellung unseres
        Onlineangebotes und Nutzerfreundlichkeit.
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f) DSGVO).
      </li>
    </ul>
    <h2 id="m17">Newsletter und elektronische Benachrichtigungen</h2>
    <p>
      Wir versenden Newsletter, E-Mails und weitere elektronische
      Benachrichtigungen (nachfolgend "Newsletter“) nur mit der Einwilligung der
      Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer
      Anmeldung zum Newsletter dessen Inhalte konkret umschrieben werden, sind
      sie für die Einwilligung der Nutzer maßgeblich. Im Übrigen enthalten
      unsere Newsletter Informationen zu unseren Leistungen und uns.
    </p>
    <p>
      Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus,
      wenn Sie Ihre E-Mail-Adresse angeben. Wir können Sie jedoch bitten, einen
      Namen, zwecks persönlicher Ansprache im Newsletter, oder weitere Angaben,
      sofern diese für die Zwecke des Newsletters erforderlich sind, zu tätigen.
    </p>
    <p>
      <strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem
      Newsletter erfolgt grundsätzlich in einem sogenannte
      Double-Opt-In-Verfahren. D.h., Sie erhalten nach der Anmeldung eine
      E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden.
      Diese Bestätigung ist notwendig, damit sich niemand mit fremden
      E-Mail-Adressen anmelden kann. Die Anmeldungen zum Newsletter werden
      protokolliert, um den Anmeldeprozess entsprechend den rechtlichen
      Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des
      Anmelde- und des Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso
      werden die Änderungen Ihrer bei dem Versanddienstleister gespeicherten
      Daten protokolliert.
    </p>
    <p>
      <strong>Löschung und Einschränkung der Verarbeitung: </strong> Wir können
      die ausgetragenen E-Mail-Adressen bis zu drei Jahren auf Grundlage unserer
      berechtigten Interessen speichern, bevor wir sie löschen, um eine ehemals
      gegebene Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten
      wird auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein
      individueller Löschungsantrag ist jederzeit möglich, sofern zugleich das
      ehemalige Bestehen einer Einwilligung bestätigt wird. Im Fall von
      Pflichten zur dauerhaften Beachtung von Widersprüchen behalten wir uns die
      Speicherung der E-Mail-Adresse alleine zu diesem Zweck in einer Sperrliste
      (sogenannte "Blocklist") vor.
    </p>
    <p>
      Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer
      berechtigten Interessen zu Zwecken des Nachweises seines ordnungsgemäßen
      Ablaufs. Soweit wir einen Dienstleister mit dem Versand von E-Mails
      beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen an
      einem effizienten und sicheren Versandsystem.
    </p>
    <strong>Inhalte:</strong>
    <p>Informationen zu uns, unseren Leistungen, Aktionen und Angeboten.</p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
        Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Meta-,
        Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
        Identifikationsnummern, Einwilligungsstatus); Nutzungsdaten (z.B.
        besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).
      </li>
      <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per
        E-Mail oder postalisch).
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
        a) DSGVO).
      </li>
      <li>
        <strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Sie können den
        Empfang unseres Newsletters jederzeit kündigen, d.h. Ihre Einwilligungen
        widerrufen, bzw. dem weiteren Empfang widersprechen. Einen Link zur
        Kündigung des Newsletters finden Sie entweder am Ende eines jeden
        Newsletters oder können sonst eine der oben angegebenen
        Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu nutzen.
      </li>
    </ul>
    <h2 id="m638">
      Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon
    </h2>
    <p>
      Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen
      Kommunikation, die über diverse Kanäle, wie z.B. E-Mail, Telefon, Post
      oder Fax, entsprechend den gesetzlichen Vorgaben erfolgen kann.
    </p>
    <p>
      Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit zu
      widerrufen oder der werblichen Kommunikation jederzeit zu widersprechen.
    </p>
    <p>
      Nach Widerruf oder Widerspruch speichern wir die zum Nachweis der
      bisherigen Berechtigung erforderlichen Daten zur Kontaktaufnahme oder
      Zusendung bis zu drei Jahre nach Ablauf des Jahres des Widerrufs oder
      Widerspruchs auf der Grundlage unserer berechtigten Interessen. Die
      Verarbeitung dieser Daten ist auf den Zweck einer möglichen Abwehr von
      Ansprüchen beschränkt. Auf der Grundlage des berechtigten Interesses, den
      Widerruf bzw. Widerspruch der Nutzer dauerhaft zu beachten, speichern wir
      ferner die zur Vermeidung einer erneuten Kontaktaufnahme erforderlichen
      Daten (z.B. je nach Kommunikationskanal die E-Mail-Adresse, Telefonnummer,
      Name).
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
        Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern).
      </li>
      <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per
        E-Mail oder postalisch).
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
        a) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
      </li>
    </ul>
    <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
    <p>
      Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der
      Auswertung der Besucherströme unseres Onlineangebotes und kann Verhalten,
      Interessen oder demographische Informationen zu den Besuchern, wie z.B.
      das Alter oder das Geschlecht, als pseudonyme Werte umfassen. Mit Hilfe
      der Reichweitenanalyse können wir z.B. erkennen, zu welcher Zeit unser
      Onlineangebot oder dessen Funktionen oder Inhalte am häufigsten genutzt
      werden oder zur Wiederverwendung einladen. Ebenso können wir
      nachvollziehen, welche Bereiche der Optimierung bedürfen.
    </p>
    <p>
      Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B.
      unterschiedliche Versionen unseres Onlineangebotes oder seiner
      Bestandteile zu testen und optimieren.
    </p>
    <p>
      Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken
      Profile, d.h. zu einem Nutzungsvorgang zusammengefasste Daten angelegt und
      Informationen in einem Browser, bzw. in einem Endgerät gespeichert und aus
      diesem ausgelesen werden. Zu den erhobenen Angaben gehören insbesondere
      besuchte Webseiten und dort genutzte Elemente sowie technische Angaben,
      wie der verwendete Browser, das verwendete Computersystem sowie Angaben zu
      Nutzungszeiten. Sofern Nutzer in die Erhebung ihrer Standortdaten uns
      gegenüber oder gegenüber den Anbietern der von uns eingesetzten Dienste
      einverstanden erklärt haben, können auch Standortdaten verarbeitet werden.
    </p>
    <p>
      Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen
      wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der
      IP-Adresse) zum Schutz der Nutzer. Generell werden die im Rahmen von
      Webanalyse, A/B-Testings und Optimierung keine Klardaten der Nutzer (wie
      z.B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h.,
      wir als auch die Anbieter der eingesetzten Software kennen nicht die
      tatsächliche Identität der Nutzer, sondern nur den für Zwecke der
      jeweiligen Verfahren in deren Profilen gespeicherten Angaben.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
        Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-,
        Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
        Identifikationsnummern, Einwilligungsstatus).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
        Nutzer von Onlinediensten).
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
        Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Profile mit
        nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
      </li>
      <li>
        <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der
        IP-Adresse).
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
        a) DSGVO).
      </li>
    </ul>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong>Matomo: </strong>Bei Matomo handelt es sich um eine Software,
        die zu Zwecken der Webanalyse und Reichweitenmessung eingesetzt wird. Im
        Rahmen der Nutzung von Matomo werden Cookies erzeugt und auf dem
        Endgerät der Nutzer gespeichert. Die im Rahmen der Nutzung von Matomo
        erhobenen Daten der Nutzer werden nur von uns verarbeitet und nicht mit
        Dritten geteilt. Die Cookies werden für einen maximalen Zeitraum von 13
        Monaten gespeichert:
        <a href="https://matomo.org/faq/general/faq_146/" target="_blank"
          >https://matomo.org/faq/general/faq_146/</a
        >; <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
        lit. a) DSGVO); <strong>Löschung von Daten:</strong> Die Cookies haben
        eine Speicherdauer von maximal 13 Monaten.
      </li>
    </ul>
    <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
    <p>
      Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
      verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort aktiven
      Nutzern zu kommunizieren oder um Informationen über uns anzubieten.
    </p>
    <p>
      Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes
      der Europäischen Union verarbeitet werden können. Hierdurch können sich
      für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte
      der Nutzer erschwert werden könnte.
    </p>
    <p>
      Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
      Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können z.B.
      anhand des Nutzungsverhaltens und sich daraus ergebender Interessen der
      Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können
      wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb
      der Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer
      entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den
      Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die
      Interessen der Nutzer gespeichert werden. Ferner können in den
      Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten
      Geräte gespeichert werden (insbesondere, wenn die Nutzer Mitglieder der
      jeweiligen Plattformen sind und bei diesen eingeloggt sind).
    </p>
    <p>
      Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und
      der Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
      Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.
    </p>
    <p>
      Auch im Fall von Auskunftsanfragen und der Geltendmachung von
      Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei
      den Anbietern geltend gemacht werden können. Nur die Anbieter haben
      jeweils Zugriff auf die Daten der Nutzer und können direkt entsprechende
      Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe
      benötigen, dann können Sie sich an uns wenden.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
        Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
        Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
        Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. B.
        IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
        Nutzer von Onlinediensten).
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
        Kommunikation; Feedback (z.B. Sammeln von Feedback via Online-Formular);
        Marketing.
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f) DSGVO).
      </li>
    </ul>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong>Facebook-Seiten: </strong>Profile innerhalb des sozialen
        Netzwerks Facebook - Wir sind gemeinsam mit Meta Platforms Ireland
        Limited für die Erhebung (jedoch nicht die weitere Verarbeitung) von
        Daten der Besucher unserer Facebook-Seite (sog. "Fanpage")
        verantwortlich. Zu diesen Daten gehören Informationen zu den Arten von
        Inhalten, die Nutzer sich ansehen oder mit denen sie interagieren, oder
        die von ihnen vorgenommenen Handlungen (siehe unter „Von dir und anderen
        getätigte und bereitgestellte Dinge“ in der Facebook-Datenrichtlinie:
        <a href="https://www.facebook.com/policy" target="_blank"
          >https://www.facebook.com/policy</a
        >), sowie Informationen über die von den Nutzern genutzten Geräte (z. B.
        IP-Adressen, Betriebssystem, Browsertyp, Spracheinstellungen,
        Cookie-Daten; siehe unter „Geräteinformationen“ in der
        Facebook-Datenrichtlinie:
        <a href="https://www.facebook.com/policy" target="_blank"
          >https://www.facebook.com/policy</a
        >). Wie in der Facebook-Datenrichtlinie unter „Wie verwenden wir diese
        Informationen?“ erläutert, erhebt und verwendet Facebook Informationen
        auch, um Analysedienste, so genannte "Seiten-Insights", für
        Seitenbetreiber bereitzustellen, damit diese Erkenntnisse darüber
        erhalten, wie Personen mit ihren Seiten und mit den mit ihnen
        verbundenen Inhalten interagieren. Wir haben mit Facebook eine spezielle
        Vereinbarung abgeschlossen ("Informationen zu Seiten-Insights",
        <a
          href="https://www.facebook.com/legal/terms/page_controller_addendum"
          target="_blank"
          >https://www.facebook.com/legal/terms/page_controller_addendum</a
        >), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen
        Facebook beachten muss und in der Facebook sich bereit erklärt hat die
        Betroffenenrechte zu erfüllen (d. h. Nutzer können z. B. Auskünfte oder
        Löschungsanfragen direkt an Facebook richten). Die Rechte der Nutzer
        (insbesondere auf Auskunft, Löschung, Widerspruch und Beschwerde bei
        zuständiger Aufsichtsbehörde), werden durch die Vereinbarungen mit
        Facebook nicht eingeschränkt. Weitere Hinweise finden sich in den
        "Informationen zu Seiten-Insights" (<a
          href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
          target="_blank"
          >https://www.facebook.com/legal/terms/information_about_page_insights_data</a
        >); <strong>Dienstanbieter:</strong> Meta Platforms Ireland Limited, 4
        Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland;
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f) DSGVO); <strong>Website:</strong>
        <a href="https://www.facebook.com" target="_blank"
          >https://www.facebook.com</a
        >; <strong>Datenschutzerklärung:</strong>
        <a href="https://www.facebook.com/about/privacy" target="_blank"
          >https://www.facebook.com/about/privacy</a
        >;
        <strong
          >Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei
          Verarbeitung in Drittländern):</strong
        >
        <a
          href="https://www.facebook.com/legal/EU_data_transfer_addendum"
          target="_blank"
          >https://www.facebook.com/legal/EU_data_transfer_addendum</a
        >; <strong>Weitere Informationen:</strong> Vereinbarung gemeinsamer
        Verantwortlichkeit:
        <a
          href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
          target="_blank"
          >https://www.facebook.com/legal/terms/information_about_page_insights_data</a
        >. Die gemeinsame Verantwortlichkeit beschränkt sich auf die Erhebung
        durch und Übermittlung von Daten an Meta Platforms Ireland Limited, ein
        Unternehmen mit Sitz in der EU. Die weitere Verarbeitung der Daten liegt
        in der alleinigen Verantwortung von Meta Platforms Ireland Limited, was
        insbesondere die Übermittlung der Daten an die Muttergesellschaft Meta
        Platforms, Inc. in den USA betrifft (auf der Grundlage der zwischen Meta
        Platforms Ireland Limited und Meta Platforms, Inc. geschlossenen
        Standardvertragsklauseln).
      </li>
      <li>
        <strong>LinkedIn: </strong>Soziales Netzwerk;
        <strong>Dienstanbieter:</strong> LinkedIn Irland Unlimited Company,
        Wilton Plaza Wilton Place, Dublin 2, Irland;
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f) DSGVO); <strong>Website:</strong>
        <a href="https://www.linkedin.com" target="_blank"
          >https://www.linkedin.com</a
        >; <strong>Datenschutzerklärung:</strong>
        <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank"
          >https://www.linkedin.com/legal/privacy-policy</a
        >; <strong>Auftragsverarbeitungsvertrag:</strong>
        <a href="https://legal.linkedin.com/dpa" target="_blank"
          >https://legal.linkedin.com/dpa</a
        >;
        <strong
          >Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei
          Verarbeitung in Drittländern):</strong
        >
        <a href="https://legal.linkedin.com/dpa" target="_blank"
          >https://legal.linkedin.com/dpa</a
        >; <strong>Widerspruchsmöglichkeit (Opt-Out):</strong>
        <a
          href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
          target="_blank"
          >https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a
        >.
      </li>
    </ul>
    <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
    <p>
      Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die
      von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet als
      "Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel um
      Grafiken, Videos oder Stadtpläne handeln (nachfolgend einheitlich
      bezeichnet als "Inhalte”).
    </p>
    <p>
      Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte
      die IP-Adresse der Nutzer verarbeiten, da sie ohne die IP-Adresse die
      Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit
      für die Darstellung dieser Inhalte oder Funktionen erforderlich. Wir
      bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter die
      IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter
      können ferner sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web
      Beacons" bezeichnet) für statistische oder Marketingzwecke verwenden.
      Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf
      den Seiten dieser Webseite, ausgewertet werden. Die pseudonymen
      Informationen können ferner in Cookies auf dem Gerät der Nutzer
      gespeichert werden und unter anderem technische Informationen zum Browser
      und zum Betriebssystem, zu verweisenden Webseiten, zur Besuchszeit sowie
      weitere Angaben zur Nutzung unseres Onlineangebotes enthalten als auch mit
      solchen Informationen aus anderen Quellen verbunden werden.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
        Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-,
        Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
        Identifikationsnummern, Einwilligungsstatus); Event-Daten (Facebook)
        ("Event-Daten" sind Daten, die z. B. via Facebook-Pixel (via Apps oder
        auf anderen Wegen) von uns an Facebook übermittelt werden können und
        sich auf Personen oder deren Handlungen beziehen; Zu den Daten gehören
        z. B. Angaben über Besuche auf Websites, Interaktionen mit Inhalten,
        Funktionen, Installationen von Apps, Käufe von Produkten, etc.; die
        Event-Daten werden zwecks Bildung von Zielgruppen für Inhalte und
        Werbeinformationen (Custom Audiences) verarbeitet; Event Daten
        beinhalten nicht die eigentlichen Inhalte (wie z. B. verfasste
        Kommentare), keine Login-Informationen und keine Kontaktinformationen
        (also keine Namen, E-Mail-Adressen und Telefonnummern). Event Daten
        werden durch Facebook nach maximal zwei Jahren gelöscht, die aus ihnen
        gebildeten Zielgruppen mit der Löschung unseres Facebook-Kontos).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
        Nutzer von Onlinediensten).
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
        Onlineangebotes und Nutzerfreundlichkeit; Marketing; Profile mit
        nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f) DSGVO); Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
      </li>
    </ul>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong>Facebook-Plugins und -Inhalte: </strong>Facebook Social Plugins
        und Inhalte - Hierzu können z.B. Inhalte wie Bilder, Videos oder Texte
        und Schaltflächen gehören, mit denen Nutzer Inhalte dieses
        Onlineangebotes innerhalb von Facebook teilen können. Die Liste und das
        Aussehen der Facebook Social Plugins können hier eingesehen werden:
        <a href="https://developers.facebook.com/docs/plugins/" target="_blank"
          >https://developers.facebook.com/docs/plugins/</a
        >
        - Wir sind gemeinsam mitMeta Platforms Ireland Limited für die Erhebung
        oder den Erhalt im Rahmen einer Übermittlung (jedoch nicht die weitere
        Verarbeitung) von "Event-Daten", die Facebook mittels der
        Facebook-Social-Plugins (und Einbettungsfunktionen für Inhalte), die auf
        unserem Onlineangebot ausgeführt werden, erhebt oder im Rahmen einer
        Übermittlung zu folgenden Zwecken erhält, gemeinsam verantwortlich: a)
        Anzeige von Inhalten sowie Werbeinformationen, die den mutmaßlichen
        Interessen der Nutzer entsprechen; b) Zustellung kommerzieller und
        transaktionsbezogener Nachrichten (z. B. Ansprache von Nutzern via
        Facebook-Messenger); c) Verbesserung der Anzeigenauslieferung und
        Personalisierung von Funktionen und Inhalten (z. B. Verbesserung der
        Erkennung, welche Inhalte oder Werbeinformationen mutmaßlich den
        Interessen der Nutzer entsprechen). Wir haben mit Facebook eine
        spezielle Vereinbarung abgeschlossen ("Zusatz für Verantwortliche",
        <a
          href="https://www.facebook.com/legal/controller_addendum"
          target="_blank"
          >https://www.facebook.com/legal/controller_addendum</a
        >), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen
        Facebook beachten muss (<a
          href="https://www.facebook.com/legal/terms/data_security_terms"
          target="_blank"
          >https://www.facebook.com/legal/terms/data_security_terms</a
        >) und in der Facebook sich bereit erklärt hat die Betroffenenrechte zu
        erfüllen (d. h. Nutzer können z. B. Auskünfte oder Löschungsanfragen
        direkt an Facebook richten). Hinweis: Wenn Facebook uns Messwerte,
        Analysen und Berichte bereitstellt (die aggregiert sind, d. h. keine
        Angaben zu einzelnen Nutzern erhalten und für uns anonym sind), dann
        erfolgt diese Verarbeitung nicht im Rahmen der gemeinsamen
        Verantwortlichkeit, sondern auf Grundlage eines
        Auftragsverarbeitungsvertrages ("Datenverarbeitungsbedingungen ",
        <a
          href="https://www.facebook.com/legal/terms/dataprocessing"
          target="_blank"
          >https://www.facebook.com/legal/terms/dataprocessing</a
        >) , der "Datensicherheitsbedingungen" (<a
          href="https://www.facebook.com/legal/terms/data_security_terms"
          target="_blank"
          >https://www.facebook.com/legal/terms/data_security_terms</a
        >) sowie im Hinblick auf die Verarbeitung in den USA auf Grundlage von
        Standardvertragsklauseln ("Facebook-EU-Datenübermittlungszusatz,
        <a
          href="https://www.facebook.com/legal/EU_data_transfer_addendum"
          target="_blank"
          >https://www.facebook.com/legal/EU_data_transfer_addendum</a
        >). Die Rechte der Nutzer (insbesondere auf Auskunft, Löschung,
        Widerspruch und Beschwerde bei zuständiger Aufsichtsbehörde), werden
        durch die Vereinbarungen mit Facebook nicht eingeschränkt;
        <strong>Dienstanbieter:</strong> Meta Platforms Ireland Limited, 4 Grand
        Canal Square, Grand Canal Harbour, Dublin 2, Irland;
        <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
        a) DSGVO); <strong>Website:</strong>
        <a href="https://www.facebook.com" target="_blank"
          >https://www.facebook.com</a
        >; <strong>Datenschutzerklärung:</strong>
        <a href="https://www.facebook.com/about/privacy" target="_blank"
          >https://www.facebook.com/about/privacy</a
        >.
      </li>
      <li>
        <strong>Google Fonts (Bereitstellung auf eigenem Server): </strong
        >Bereitstellung von Schriftarten-Dateien zwecks einer nutzerfreundlichen
        Darstellung unseres Onlineangebotes;
        <strong>Dienstanbieter:</strong> Die Google Fonts werden auf unserem
        Server gehostet, es werden keine Daten an Google übermittelt;
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f) DSGVO).
      </li>
      <li>
        <strong>Google Fonts (Bezug vom Google Server): </strong>Bezug von
        Schriften (und Symbolen) zum Zwecke einer technisch sicheren,
        wartungsfreien und effizienten Nutzung von Schriften und Symbolen im
        Hinblick auf Aktualität und Ladezeiten, deren einheitliche Darstellung
        und Berücksichtigung möglicher lizenzrechtlicher Beschränkungen. Dem
        Anbieter der Schriftarten wird die IP-Adresse des Nutzers mitgeteilt,
        damit die Schriftarten im Browser des Nutzers zur Verfügung gestellt
        werden können. Darüber hinaus werden technische Daten
        (Spracheinstellungen, Bildschirmauflösung, Betriebssystem, verwendete
        Hardware) übermittelt, die für die Bereitstellung der Schriften in
        Abhängigkeit von den verwendeten Geräten und der technischen Umgebung
        notwendig sind. Diese Daten können auf einem Server des Anbieters der
        Schriftarten in den USA verarbeitet werden - Beim Besuch unseres
        Onlineangebotes senden die Browser der Nutzer ihre Browser HTTP-Anfragen
        an die Google Fonts Web API (d.h. eine Softwareschnittstelle für den
        Abruf der Schriftarten). Die Google Fonts Web API stellt den Nutzern die
        Cascading Style Sheets (CSS) von Google Fonts und danach die in der CCS
        angegebenen Schriftarten zur Verfügung. Zu diesen HTTP-Anfragen gehören
        (1) die vom jeweiligen Nutzer für den Zugriff auf das Internet
        verwendete IP-Adresse, (2) die angeforderte URL auf dem Google-Server
        und (3) die HTTP-Header, einschließlich des User-Agents, der die
        Browser- und Betriebssystemversionen der Websitebesucher beschreibt,
        sowie die Verweis-URL (d.h. die Webseite, auf der die Google-Schriftart
        angezeigt werden soll). IP-Adressen werden weder auf Google-Servern
        protokolliert noch gespeichert und sie werden nicht analysiert. Die
        Google Fonts Web API protokolliert Details der HTTP-Anfragen
        (angeforderte URL, User-Agent und Verweis-URL). Der Zugriff auf diese
        Daten ist eingeschränkt und streng kontrolliert. Die angeforderte URL
        identifiziert die Schriftfamilien, für die der Nutzer Schriftarten laden
        möchte. Diese Daten werden protokolliert, damit Google bestimmen kann,
        wie oft eine bestimmte Schriftfamilie angefordert wird. Bei der Google
        Fonts Web API muss der User-Agent die Schriftart anpassen, die für den
        jeweiligen Browsertyp generiert wird. Der User-Agent wird in erster
        Linie zum Debugging protokolliert und verwendet, um aggregierte
        Nutzungsstatistiken zu generieren, mit denen die Beliebtheit von
        Schriftfamilien gemessen wird. Diese zusammengefassten
        Nutzungsstatistiken werden auf der Seite „Analysen“ von Google Fonts
        veröffentlicht. Schließlich wird die Verweis-URL protokolliert, sodass
        die Daten für die Wartung der Produktion verwendet und ein aggregierter
        Bericht zu den Top-Integrationen basierend auf der Anzahl der
        Schriftartenanfragen generiert werden kann. Google verwendet laut
        eigener Auskunft keine der von Google Fonts erfassten Informationen, um
        Profile von Endnutzern zu erstellen oder zielgerichtete Anzeigen zu
        schalten; <strong>Dienstanbieter:</strong> Google Ireland Limited,
        Gordon House, Barrow Street, Dublin 4, Irland;
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f) DSGVO); <strong>Website:</strong>
        <a href="https://fonts.google.com/" target="_blank"
          >https://fonts.google.com/</a
        >; <strong>Datenschutzerklärung:</strong>
        <a href="https://policies.google.com/privacy" target="_blank"
          >https://policies.google.com/privacy</a
        >; <strong>Weitere Informationen:</strong>
        <a
          href="https://developers.google.com/fonts/faq/privacy?hl=de"
          target="_blank"
          >https://developers.google.com/fonts/faq/privacy?hl=de</a
        >.
      </li>
      <li>
        <strong>LinkedIn-Plugins und -Inhalte: </strong>LinkedIn-Plugins und
        -Inhalte- Hierzu können z.B. Inhalte wie Bilder, Videos oder Texte und
        Schaltflächen gehören, mit denen Nutzer Inhalte dieses Onlineangebotes
        innerhalb von LinkedIn teilen können;
        <strong>Dienstanbieter:</strong> LinkedIn Ireland Unlimited Company,
        Wilton Place, Dublin 2, Irland;
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f) DSGVO); <strong>Website:</strong>
        <a href="https://www.linkedin.com" target="_blank"
          >https://www.linkedin.com</a
        >; <strong>Datenschutzerklärung:</strong>
        <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank"
          >https://www.linkedin.com/legal/privacy-policy</a
        >; <strong>Auftragsverarbeitungsvertrag:</strong>
        <a href="https://legal.linkedin.com/dpa" target="_blank"
          >https://legal.linkedin.com/dpa</a
        >;
        <strong
          >Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei
          Verarbeitung in Drittländern):</strong
        >
        <a href="https://legal.linkedin.com/dpa" target="_blank"
          >https://legal.linkedin.com/dpa</a
        >; <strong>Widerspruchsmöglichkeit (Opt-Out):</strong>
        <a
          href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
          target="_blank"
          >https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a
        >.
      </li>
    </ul>
    <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
    <p>
      Wir bitten Sie, sich regelmäßig über den Inhalt unserer
      Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung
      an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen
      dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen
      eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige
      individuelle Benachrichtigung erforderlich wird.
    </p>
    <p>
      Sofern wir in dieser Datenschutzerklärung Adressen und
      Kontaktinformationen von Unternehmen und Organisationen angeben, bitten
      wir zu beachten, dass die Adressen sich über die Zeit ändern können und
      bitten die Angaben vor Kontaktaufnahme zu prüfen.
    </p>
    <h2 id="m10">Rechte der betroffenen Personen</h2>
    <p>
      Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die
      sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
    </p>
    <ul>
      <li>
        <strong
          >Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
          Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
          der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6
          Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt
          auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die
          Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung
          zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
          Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke
          derartiger Werbung einzulegen; dies gilt auch für das Profiling,
          soweit es mit solcher Direktwerbung in Verbindung steht.</strong
        >
      </li>
      <li>
        <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht,
        erteilte Einwilligungen jederzeit zu widerrufen.
      </li>
      <li>
        <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung
        darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
        Auskunft über diese Daten sowie auf weitere Informationen und Kopie der
        Daten entsprechend den gesetzlichen Vorgaben.
      </li>
      <li>
        <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
        gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
        betreffenden Daten oder die Berichtigung der Sie betreffenden
        unrichtigen Daten zu verlangen.
      </li>
      <li>
        <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong>
        Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
        verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw.
        alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der
        Verarbeitung der Daten zu verlangen.
      </li>
      <li>
        <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht,
        Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe
        der gesetzlichen Vorgaben in einem strukturierten, gängigen und
        maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen
        anderen Verantwortlichen zu fordern.
      </li>
      <li>
        <strong>Beschwerde bei Aufsichtsbehörde:</strong> Entsprechend den
        gesetzlichen Vorgaben und unbeschadet eines anderweitigen
        verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs, haben Sie
        ferner das Recht, bei einer Datenschutzaufsichtsbehörde, insbesondere
        einer Aufsichtsbehörde im Mitgliedstaat, in dem Sie sich gewöhnlich
        aufhalten, der Aufsichtsbehörde Ihres Arbeitsplatzes oder des Ortes des
        mutmaßlichen Verstoßes, eine Beschwerde einzulegen, wenn Sie der Ansicht
        sei sollten, dass die Verarbeitung der Ihre Person betreffenden
        personenbezogenen Daten gegen die DSGVO verstößt.
      </li>
    </ul>
    <h2 id="m42">Begriffsdefinitionen</h2>
    <p>
      In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
      Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe
      sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
      gesetzlichen Definitionen sind verbindlich. Die nachfolgenden
      Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die
      Begriffe sind alphabetisch sortiert.
    </p>
    <ul class="glossary">
      <li>
        <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind
        alle Informationen, die sich auf eine identifizierte oder
        identifizierbare natürliche Person (im Folgenden "betroffene Person“)
        beziehen; als identifizierbar wird eine natürliche Person angesehen, die
        direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung
        wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer
        Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen
        Merkmalen identifiziert werden kann, die Ausdruck der physischen,
        physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen
        oder sozialen Identität dieser natürlichen Person sind.
      </li>
      <li>
        <strong>Profile mit nutzerbezogenen Informationen:</strong> Die
        Verarbeitung von "Profilen mit nutzerbezogenen Informationen", bzw. kurz
        "Profilen" umfasst jede Art der automatisierten Verarbeitung
        personenbezogener Daten, die darin besteht, dass diese personenbezogenen
        Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf
        eine natürliche Person beziehen (je nach Art der Profilbildung können
        dazu unterschiedliche Informationen betreffend die Demographie,
        Verhalten und Interessen, wie z.B. die Interaktion mit Webseiten und
        deren Inhalten, etc.) zu analysieren, zu bewerten oder, um sie
        vorherzusagen (z.B. die Interessen an bestimmten Inhalten oder
        Produkten, das Klickverhalten auf einer Webseite oder den
        Aufenthaltsort). Zu Zwecken des Profilings werden häufig Cookies und
        Web-Beacons eingesetzt.
      </li>
      <li>
        <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als
        Web Analytics bezeichnet) dient der Auswertung der Besucherströme eines
        Onlineangebotes und kann das Verhalten oder Interessen der Besucher an
        bestimmten Informationen, wie z.B. Inhalten von Webseiten, umfassen. Mit
        Hilfe der Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu
        welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie
        sich interessieren. Dadurch können sie z.B. die Inhalte der Webseite
        besser an die Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der
        Reichweitenanalyse werden häufig pseudonyme Cookies und Web-Beacons
        eingesetzt, um wiederkehrende Besucher zu erkennen und so genauere
        Analysen zur Nutzung eines Onlineangebotes zu erhalten.
      </li>
      <li>
        <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
        natürliche oder juristische Person, Behörde, Einrichtung oder andere
        Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel
        der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
      </li>
      <li>
        <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne
        Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
        Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff
        reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das
        Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen.
      </li>
    </ul>
    <p class="seal">
      <a
        href="https://datenschutz-generator.de/"
        title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
        target="_blank"
        rel="noopener noreferrer nofollow"
        >Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
        Schwenke</a
      >
    </p>
  </div>
</template>
<script>
export default {
  mounted() {
    setTimeout(() => window.scrollTo(0, 0), 250);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles.scss";
h1 {
  margin-top: 20px;
}
</style>
