<template>
  <div id="app">
    <Navbar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
  components: {
    Navbar,
    Footer,
  },
  beforeMount() {
    document.title = this.$t('title');
  }
};
</script>

<style lang="scss">
@import "@/assets/styles.scss";

html,
body {
  margin: 0;
  overflow-x: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #eee;
  display: inline-flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  section:last-child {
    margin-bottom: 75px;
  }
  >div {
    flex-grow: 2;
    min-height: 100vh;
    padding-top: 110px;
    position: relative;
  }
}

.overlay {
  display: inline-flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

a {
  color: unset !important;
  text-decoration: unset !important;
}

button {
  background-color: $accent_dark !important;
  border-color: transparent !important;
}

section {
  //display: inline-flex;
  margin-top: 75px;
  width: 100%;
  .container {
    //max-width: 1500px !important;
    > p {
      text-align: center;
      max-width: 60%;
      margin: 0 auto;
      margin-bottom: 50px;
    }
  }
  h2 {
    display: inline-flex;
    width: 100%;
    font-size: 2rem;
    text-align: center;
    flex-direction: column;
    margin-bottom: 25px;

    &::after {
      content: "";
      display: inline-block;
      margin: 10px auto 0;
      width: 100px;
      height: 2px;
      background-color: $accent;
      bottom: 0;
    }
  }
}
</style>
