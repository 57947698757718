<template>
  <div class="container">
    <h1>Erstinformation</h1>

    <b>Ben Beutler-Huth</b><br />
    Wilhelmstraße 135<br />
    14624 Dallgow-Döberitz<br />
    Telefon: +49 173 42 79 202<br />
    E-Mail: kontakt@beutler-makler.de <br />
    <br />
    - nachfolgend Versicherungsmakler genannt –<br />
    <br />
    "Ben Beutler" ist als Versicherungsmakler auf der Grundlage einer
    Gewerbegenehmigung gem. § 34 d Abs. 1 Gewerbeordnung tätig. Die Tätigkeit
    des Versicherungsmaklers beinhaltet die Beratung.<br />
    <br />
    <b>Erlaubnisbehörde für die Erteilung der vorgenannten Gewerbegenehmigung
    ist:</b><br />
    <br />
    Industrie- und Handelskammer Potsdam<br />
    Breite Straße 2 a-c<br />
    14467 Potsdam<br />
    <br />
    <b>Der Kunde kann die Eintragung im Vermittlerregister bei der gemeinsamen
    Registerstelle überprüfen:</b><br />
    <br />
    Deutscher Industrie- und Handelskammertag (DIHK) e.V.<br />
    Breite Straße 29, 10178 Berlin<br />
    Telefon: 0180-6005850 (0,20 Euro/Anruf)<br />
    Fax: +49 (0) 30-20308-1000, E-Mail: infocenter@berlin.dihk.de,<br />
    Registerabruf: <b>www.vermittlerregister.info</b><br />
    <br />
    Die Registrierungs-Nummer des Versicherungsmaklers lautet:
    <b>D-LSOI-59QCZ-99</b><br />
    <br />
    Es bestehen keine direkten oder indirekten Beteiligungen von über 10% an den
    Stimmrechten oder am Kapital eines Versicherungsunternehmens (gem. § 15 Abs.
    1 Nr. 10 VersVermV). <br />
    <br />
    Darüber hinaus haben keine Versicherungsunternehmen oder Mutterunternehmen
    direkte oder indirekte Beteiligungen von über 10% an dem Unternehmen des
    Gewerbetreibenden/Versicherungsmaklers (gem. § 15 Abs. 1 Nr. 11 VersVermV).
    <br />
    <br />
      Bei Streitigkeiten kann sich der Kunde zur außergerichtlichen
    Streitbeilegung an nachfolgende Schlichtungsstellen wenden:<br />
    <br />
    Schlichtungsstellen - außergerichtliche Streitbeilegung<br />
    Versicherungsombudsmann e.V.<br />
    Postfach 08 06 32, 10006 Berlin<br />
    www.versicherungsombudsmann.de<br />
    <br />
    Ombudsmann für private Kranken- und Pflegeversicherung<br />
    Postfach 06 02 22, 10052 Berlin<br />
    www.pkv-ombudsmann.de<br />
    <br />
    Der Versicherungsmakler erhält seine Vergütung von den
    Versicherungsgesellschaften in Form einer Provision/Courtage, die in der
    Versicherungsprämie des Kunden enthalten ist. Die Zahlung eines Honorars
    durch den Kunden bedarf einer gesonderten Vereinbarung.
  </div>
</template>
<script>
export default {
  mounted() {
    setTimeout(() => window.scrollTo(0, 0), 250);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles.scss";
h1 {
  margin-top: 20px;
}
.container {
  padding-bottom: 20px;
}
</style>
